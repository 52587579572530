<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileIcon" />
          <span>Teklif</span>
        </template>
        <validation-observer ref="simpleRules">
          <offer-form />
          <div
            v-for="(lineData,key) in dataItem.lines"
            :key="key"
          >
            <offer-product
              :data-key="key"
            />
          </div>
          <term-card />
          <b-row>
            <b-col>
              <b-button
                :disabled="dataItem.submitStatus"
                variant="primary"
                @click="submitData"
              >
                Kaydet
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Kartı</span>
        </template>
        <customer />
      </b-tab>
    </b-tabs>

  </div>
</template>
<script>

import {
  BRow, BCol, BButton, BTab, BTabs,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import OfferForm from '@/views/BatteryOffers/Components/OfferForm.vue'
import OfferProduct from '@/views/BatteryOffers/Components/Products.vue'
import TermCard from '@/views/BatteryOffers/Components/TermCard.vue'
import Customer from '@/views/Crm/View/Customer.vue'

export default {
  name: 'OfferAdd',
  components: {
    BTabs,
    Customer,
    BTab,
    TermCard,
    OfferProduct,
    OfferForm,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
  },
  computed: {
    dataItem() {
      return this.$store.getters['batteryOffers/getOffer']
    },
    lineData() {
      const lineData = this.$store.getters['batteryOffers/getOffer']
      return lineData.lines
    },
    offerTerm() {
      return this.$store.getters['batteryOffers/getTermsContent']
    },
    saveData() {
      return this.$store.getters['batteryOffers/getOfferSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.$router.push(`/battery-offers/view/${val.id}`)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.dataItem.submitStatus = false
    },
  },
  created() {
    localize('tr')
    this.$store.commit('batteryOffers/OFFER_RESET')
    this.$store.commit('offerLines/OFFER_LINE_RESET')
    this.$store.commit('offerLinePriceItems/RESET_OFFER_LINE_PRICE_ITEMS_LIST')
    this.getCustomer()
    this.addLineItem()
  },
  methods: {
    addLineItem() {
      this.dataItem.lines.push({
        id: null,
        title: null,
        offer_price: null,
        id_com_battery_offer: null,
        id_com_user: null,
      })
    },
    getCustomer() {
      this.$store.dispatch('customers/customerView', this.$route.params.id_customer)
        .then(response => {
          if (response) {
            this.getTerms(response.id)
          }
        })
    },
    getTerms(id) {
      this.$store.dispatch('batteryOffers/offerTermContent', id)
        .then(response => {
          if (response) {
            this.dataItem.content = response.content
            this.dataItem.terms = response.term
          }
        })
    },
    submitData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.dataItem.id_com_customer = this.$route.params.id_customer
          this.dataItem.id_com_service_tracing = this.$route.params.id_com_service_tracing
          this.dataItem.id_com_cars = this.$route.params.id_com_cars
          this.$store.dispatch('batteryOffers/offerSave', this.dataItem)
        }
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
